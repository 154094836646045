import { Route } from '@angular/router';
import { PmAuthGuardService } from '@pm/auth/utils';
import { AppLayoutComponent } from '@pm/core/layout';
import { PmCorePageNotFoundComponent } from '@pm/core/page';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [PmAuthGuardService],
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('@pm/dashboard/page').then((m) => m.PmDashboardPageModule),
      },
      {
        path: 'applications',
        loadChildren: () =>
          import('@pm/application/page-client').then(
            (m) => m.PmApplicationPageClientModule,
          ),
      },
      {
        path: 'trademarks',
        loadChildren: () =>
          import('@pm/trademark/page-client').then(
            (m) => m.PmTrademarkPageClientModule,
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('@pm/report/page-client').then(
            (m) => m.PmReportPageClientModule,
          ),
      },
      {
        path: 'domains',
        loadChildren: () =>
          import('@pm/domains/page-client').then(
            (m) => m.PmDomainsPageClientModule,
          ),
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('@pm/invoice/page-client').then(
            (m) => m.PmInvoicePageClientModule,
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('@pm/profile/page-client').then(
            (m) => m.PmProfilePageClientModule,
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('@pm/search/page-client').then(
            (m) => m.PmSearchPageClientModule,
          ),
      },
      {
        path: 'designs',
        loadChildren: () =>
          import('@pm/design/page-client').then(
            (m) => m.PmDesignPageClientModule,
          ),
      },
      {
        path: 'ip',
        loadChildren: () =>
          import('@pm/ip/page-client').then((m) => m.PM_IP_PAGE_CLIENT_ROUTES),
      },
      {
        path: 'partner-program',
        loadChildren: () =>
          import('@pm/partner/page-client').then(
            (m) => m.pmPartnersPageClientRoutes,
          ),
      },
    ],
  },
  {
    path: 'auth',
    data: { breadcrumb: 'Auth' },
    loadChildren: () =>
      import('@pm/auth/page-client').then((m) => m.PmAuthPageClientModule),
  },
  {
    path: '**',
    component: PmCorePageNotFoundComponent,
  },
];
