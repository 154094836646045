import { Component, ElementRef, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutService } from '../service/layout.service';
import { AppMenu } from './app.menu';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-sidebar]',
  standalone: true,
  imports: [AppMenu, RouterModule],
  template: ` <div
    class="layout-sidebar"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    <div class="sidebar-header">
      <a [routerLink]="['/']" class="app-logo">
        <img src="/assets/profit-logo-new-en.svg" alt="Logo" width="180" />
      </a>
      <button
        class="layout-sidebar-anchor p-link z-2 p-button p-button-text"
        type="button"
        (click)="anchor()"
      ></button>
    </div>

    <div #menuContainer class="layout-menu-container">
      <app-menu></app-menu>
    </div>
  </div>`,
})
export class AppSidebarComponent {
  timeout: any = null;

  @ViewChild('menuContainer') menuContainer!: ElementRef;
  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
  ) {}

  onMouseEnter() {
    if (!this.layoutService.layoutState().anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.layoutService.layoutState.update((state) => {
        if (!state.sidebarActive) {
          return {
            ...state,
            sidebarActive: true,
          };
        }
        return state;
      });
    }
  }

  onMouseLeave() {
    if (!this.layoutService.layoutState().anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(() => {
          this.layoutService.layoutState.update((state) => {
            if (state.sidebarActive) {
              return {
                ...state,
                sidebarActive: false,
              };
            }
            return state;
          });
        }, 300);
      }
    }
  }

  anchor() {
    this.layoutService.layoutState.update((state) => ({
      ...state,
      anchored: !state.anchored,
    }));
  }
}
