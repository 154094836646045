import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppMenuitem } from './app.menuitem';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, AppMenuitem, RouterModule],
  template: `<ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index">
      <li
        app-menuitem
        *ngIf="!item.separator"
        [item]="item"
        [index]="i"
        [root]="true"
      ></li>
      <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
  </ul> `,
})
export class AppMenu {
  model: any[] = [];

  /**
   * t(menu.search-tm,menu.new-application,menu.dashboard,menu.trademarks,menu.applications,menu.trademarks,menu.domains,menu.billing,menu.invoices,menu.services,menu.designs,menu.other-ips)
   */
  ngOnInit() {
    this.model = [
      {
        items: [
          {
            label: 'menu.dashboard',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/'],
          },
          {
            label: 'menu.new-application',
            icon: 'pi pi-fw pi-plus-circle',
            routerLink: ['/applications/new'],
          },
          {
            label: 'menu.search-tm',
            icon: 'pi pi-fw pi-search',
            routerLink: ['/search'],
          },
        ],
      },
      {
        label: 'menu.trademarks',
        icon: 'pi pi-th-large',
        items: [
          {
            label: 'menu.applications',
            icon: 'pi pi-fw pi-comment',
            routerLink: ['/applications'],
          },
          {
            label: 'menu.trademarks',
            icon: 'pi pi-fw pi-calendar',
            routerLink: ['/trademarks'],
          },
          {
            label: 'menu.domains',
            icon: 'pi pi-fw pi-at',
            routerLink: ['/domains'],
          },
        ],
      },
      {
        label: 'menu.billing',
        icon: 'pi pi-fw pi-calendar',
        items: [
          {
            label: 'menu.invoices',
            icon: 'pi pi-fw pi-calendar',
            routerLink: ['/invoices'],
          },
        ],
      },
      {
        label: 'menu.services',
        icon: 'pi pi-fw pi-calendar',
        items: [
          {
            label: 'menu.designs',
            icon: 'pi pi-fw pi-star',
            routerLink: ['/designs'],
          },
          {
            label: 'menu.other-ips',
            icon: 'pi pi-fw pi-star',
            routerLink: ['/ip'],
          },
        ],
      },
    ];
  }
}
