import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { DrawerModule } from 'primeng/drawer';

import { Component, computed } from '@angular/core';
import { PmSidebarComponent } from '@pm/sidebar';

import { LayoutService } from '../service/layout.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-profilesidebar]',
  imports: [ButtonModule, DrawerModule, BadgeModule, PmSidebarComponent],
  template: `
    <p-drawer
      [visible]="visible()"
      (onHide)="onDrawerHide()"
      position="right"
      [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
      styleClass="layout-profile-sidebar w-full sm:w-25rem"
    >
      <pm-sidebar (closeSidebar)="onDrawerHide()"></pm-sidebar>
    </p-drawer>
  `,
})
export class AppProfileSidebarComponent {
  constructor(public layoutService: LayoutService) {}

  visible = computed(
    () => this.layoutService.layoutState().profileSidebarVisible,
  );

  onDrawerHide() {
    this.layoutService.layoutState.update((state) => ({
      ...state,
      profileSidebarVisible: false,
    }));
  }
}
