import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { Observable, mergeMap, of } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { PmAuthService } from '@pm/auth/utils';
import { PmNotificationPushService } from '@pm/notification/utils';

@Component({
    selector: 'pm-notification',
    imports: [
        CommonModule,
        ToastModule,
        DialogModule,
        ButtonModule,
        TranslocoModule,
    ],
    templateUrl: './pm-notification.component.html',
    styleUrls: ['./pm-notification.component.scss']
})
export class PmNotificationComponent implements OnInit {
  init$!: Observable<boolean>;
  visible = false;
  constructor(
    private readonly _push: PmNotificationPushService,
    private readonly _auth: PmAuthService,
  ) {}

  ngOnInit(): void {
    const subscriptionOffered = localStorage.getItem('subscription_offered');

    this.init$ = !subscriptionOffered
      ? this._auth.isLoggedIn$.pipe(
          mergeMap((isSignedIn) => {
            if (isSignedIn) {
              this.visible = true;
              return of(true);
            }
            return of(false);
          }),
        )
      : of(false);
  }

  onAccept(): void {
    localStorage.setItem('subscription_offered', 'true');
    this.visible = false;
    this._push.init();
  }

  onDecline(): void {
    localStorage.setItem('subscription_offered', 'true');
    this.visible = false;
  }
}
