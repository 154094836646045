<ng-container *ngIf="notification$ | async"></ng-container>

<ng-container *transloco="let t; read: 'ui'">
  <div class="flex flex-column min-h-full">
    <div class="pm-sidebar flex flex-column mx-auto md:mx-0">
      <span class="mb-2 font-semibold">
        {{ t('welcome') }}
      </span>
      <span class="text-color-secondary font-medium mb-5">{{
        displayname
      }}</span>

      <ul class="list-none m-0 p-0">
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round transition-colors transition-duration-150"
            [routerLink]="['/profile']"
            (click)="close()"
          >
            <span>
              <i class="pi pi-user text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">
                {{ t('profile') }}
              </span>
              <p class="text-color-secondary m-0">
                {{ t('view-your-profile') }}
              </p>
            </div>
          </a>
        </li>
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round transition-colors transition-duration-150"
            [routerLink]="['/partner-program']"
            (click)="close()"
          >
            <span>
              <i class="pi pi-bolt text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">
                {{ t('partner-program') }}
              </span>
              <p class="text-color-secondary m-0">
                {{ t('partner-config-and-rewards') }}
              </p>
            </div>
          </a>
        </li>
        <li>
          <a
            (click)="logout()"
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round transition-colors transition-duration-150"
          >
            <span>
              <i class="pi pi-power-off text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">
                {{ t('sign-out') }}
              </span>
              <p class="text-color-secondary m-0">
                {{ t('sign-out-of-your-account') }}
              </p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="w-full flex flex-column mt-5 mx-auto md:mx-0">
      <span class="mb-2 font-semibold">{{ t('notifications') }}</span>
      <span class="text-color-secondary font-medium mb-5">{{
        t('notifications-count', {
          count: notificationCount$ | async
        })
      }}</span>

      <ul class="list-none m-0 p-0">
        @for (notification of notifications; track notification.id) {
          <li>
            <div
              class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round transition-colors transition-duration-150 gap-2"
            >
              <div>
                @switch (notification.severity) {
                  @case ('info') {
                    <i
                      class="pi pi-info-circle text-blue-500 mr-2 text-2xl"
                    ></i>
                  }
                  @case ('warning') {
                    <i
                      class="pi pi-exclamation-triangle text-orange-500 mr-2 text-2xl"
                    ></i>
                  }
                  @case ('critical') {
                    <i
                      class="pi pi-times-circle text-red-500 mr-2 text-2xl"
                    ></i>
                  }
                  @default {
                    <i
                      class="pi pi-info-circle text-blue-500 mr-2 text-2xl"
                    ></i>
                  }
                }
              </div>
              <div>
                <span class="mb-2 font-semibold">{{
                  notification.message
                }}</span>
                <p class="text-color-secondary m-0 text-sm">
                  {{ notification.send_date | date: 'YYYY-MM-dd' }}
                </p>
              </div>
              @if (!notification.action) {
                <div class="ml-auto">
                  <p-button
                    icon="pi pi-eye"
                    class="ml-auto"
                    (onClick)="readNotification(notification)"
                  ></p-button>
                </div>
              }
            </div>
          </li>
        }
      </ul>
    </div>
    <div class="mt-auto text-sm flex flex-row gap-2 align-items-center">
      <div>{{ t('version') }}: {{ version }}</div>
      @if (isUpdateAvailable) {
        <p-button
          [label]="t('update-application')"
          severity="warn"
          [text]="true"
          (onClick)="setLatestVersion()"
        ></p-button>
      }
    </div>
  </div>

  <!--
  <div class="flex flex-column mt-5 mx-auto md:mx-0">
    <span class="mb-2 font-semibold">Messages</span>
    <span class="text-color-secondary font-medium mb-5"
      >You have new messages</span
    >

    <ul class="list-none m-0 p-0">
      <li>
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round transition-colors transition-duration-150"
        >
          <span>
            <img
              src="assets/demo/images/avatar/circle/avatar-m-8.png"
              alt="Avatar"
              class="w-2rem h-2rem"
            />
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">James Robinson</span>
            <p class="text-color-secondary m-0">10 min ago</p>
          </div>
          <p-badge value="3" class="ml-auto"></p-badge>
        </a>
      </li>
      <li>
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round transition-colors transition-duration-150"
        >
          <span>
            <img
              src="assets/demo/images/avatar/circle/avatar-f-8.png"
              alt="Avatar"
              class="w-2rem h-2rem"
            />
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">Mary Watson</span>
            <p class="text-color-secondary m-0">15min ago</p>
          </div>
          <p-badge value="1" class="ml-auto"></p-badge>
        </a>
      </li>
      <li>
        <a
          class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round transition-colors transition-duration-150"
        >
          <span>
            <img
              src="assets/demo/images/avatar/circle/avatar-f-4.png"
              alt="Avatar"
              class="w-2rem h-2rem"
            />
          </span>
          <div class="ml-3">
            <span class="mb-2 font-semibold">Aisha Webb</span>
            <p class="text-color-secondary m-0">3h ago</p>
          </div>
          <p-badge value="2" class="ml-auto"></p-badge>
        </a>
      </li>
    </ul>
  </div> -->
</ng-container>
