import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';

import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { PmAuthService } from '@pm/auth/utils';
import { PmNotificationCounterComponent } from '@pm/notification/elements';

import { LayoutService } from '../service/layout.service';
import { AppBreadcrumb } from './app.breadcrumb';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-topbar]',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    StyleClassModule,
    AppBreadcrumb,
    InputTextModule,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    AvatarModule,
    PmNotificationCounterComponent,
    TranslocoModule,
  ],
  template: `<div class="layout-topbar">
    <div class="topbar-start">
      <button
        #menubutton
        type="button"
        class="topbar-menubutton p-link p-trigger"
        (click)="onMenuButtonClick()"
      >
        <i class="pi pi-bars"></i>
      </button>
      <nav app-breadcrumb class="topbar-breadcrumb"></nav>
    </div>

    <div class="topbar-end">
      <ul class="topbar-menu">
        <li>
          <button
            pButton
            type="button"
            [text]="true"
            [routerLink]="['/partner-program']"
            [routerLinkActive]="'p-disabled'"
            [icon]="'pi pi-bolt'"
          >
            <span class="hidden md:inline md:ml-2">{{
              'ui.partners-program' | transloco
            }}</span>
          </button>
        </li>
        <li class="topbar-profile">
          <button type="button" class="p-link" (click)="onProfileButtonClick()">
            <p-avatar [label]="displayname" shape="circle"></p-avatar>
          </button>
          <div style="margin-left: -6px; margin-top: -24px">
            <pm-notification-counter></pm-notification-counter>
          </div>
        </li>
      </ul>
    </div>
  </div>`,
})
export class AppTopbarComponent implements OnInit {
  @ViewChild('menubutton') menuButton!: ElementRef;

  displayname!: string;

  constructor(
    public layoutService: LayoutService,
    private readonly _auth: PmAuthService,
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
  }

  async getUserInfo() {
    const user = await this._auth.getDecodedToken();
    if (user?.drupal?.displayname) {
      const initials = user.drupal.displayname
        .split(' ')
        .map((n: string) => n[0]);
      this.displayname = initials.slice(0, 2).join('');
    }
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }
}
