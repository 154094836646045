import { BadgeModule } from 'primeng/badge';
import { map, startWith } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NotificationsFacade } from '@pm/notification/data';

@Component({
  selector: 'pm-notification-counter',
  imports: [CommonModule, BadgeModule],
  templateUrl: './pm-notification-counter.component.html',
  styleUrls: ['./pm-notification-counter.component.scss'],
})
export class PmNotificationCounterComponent {
  notificationCount$ = this._notificationFacade.unreadNotificationsCount$.pipe(
    map((count) => (count > 9 ? '9+' : count.toString())),
  );

  notificationCount = toSignal(this.notificationCount$, {
    initialValue: '0',
  });
  constructor(private readonly _notificationFacade: NotificationsFacade) {}
}
